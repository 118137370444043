//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { PatientService } from '@/services/patient.service';
import { LookupService } from '@/services/lookup.service';
import { DoctorService } from '@/services/doctor.service';
import { PharmacyService } from '@/services/pharmacy.service';
import CardPharmacy from '@/components/patients/partials/CardPharmacy';
export default {
  props: {
    rowData: {
      type: Object,

      default() {
        return {};
      }

    }
  },
  components: {
    CardPharmacy
  },

  data() {
    return {
      showDialog: false,
      buttonDisabled: false,
      alert: {
        show: false,
        message: ''
      },
      checked: false,
      searchName: '',
      searchAddress1: '',
      // searchAddress2: '',
      searchCity: '',
      searchState: '',
      searchZip: '',
      pharmacyList: [],
      currentPage: 1,
      rows: 0,
      perPage: 5,
      selectedPharmacy: {
        PharmacyId: ''
      },
      isFetching: false
    };
  },

  watch: {
    async showDialog(show) {
      if (show) {
        if (this.rowData.pharmacyorigin) {
          if (this.rowData.hastransferpharmacy) {
            this.checked = true;
          } else {
            this.checked = false;
          }

          this.selectedPharmacy = {
            PharmacyId: this.rowData.pharmacy.id,
            Address1: this.rowData.pharmacy.address || this.rowData.pharmacy.address1,
            City: this.rowData.pharmacy.city,
            State: this.rowData.pharmacy.state,
            ZipCode: this.rowData.pharmacy.zipcode,
            StoreName: this.rowData.pharmacy.name
          };
        } else {// this.checked = true;
        }
      } else {
        this.searchName = '';
        this.searchAddress1 = '';
        this.searchCity = '';
        this.searchState = '';
        this.searchZip = '';
        this.pharmacyList = [];
        this.currentPage = 1;
        this.rows = 0;
        this.perPage = 5;
        this.selectedPharmacy = {
          PharmacyId: ''
        };
        this.isFetching = false;
        this.checked = false;
      }
    },

    'selectedPharmacy.PharmacyId'() {
      this.checked = true;
    }

  },
  computed: {
    paginatedPharmacies() {
      const start = (this.currentPage - 1) * this.perPage;
      return this.pharmacyList.slice(start, start + this.perPage);
    },

    disableButton() {
      var _this$rowData, _this$rowData$pharmac;

      if (!this.rowData.hastransferpharmacy && this.selectedPharmacy.PharmacyId !== ((_this$rowData = this.rowData) === null || _this$rowData === void 0 ? void 0 : (_this$rowData$pharmac = _this$rowData.pharmacy) === null || _this$rowData$pharmac === void 0 ? void 0 : _this$rowData$pharmac.id) && this.checked) {
        return false;
      } else if (!this.checked && this.selectedPharmacy.PharmacyId && this.rowData.hastransferpharmacy) {
        return false;
      } else {
        return true;
      }
    },

    disableSwitch() {
      if (!this.rowData.hastransferpharmacy && !this.selectedPharmacy.PharmacyId) {
        return true;
      } else {
        return false;
      }
    }

  },

  created() {},

  methods: {
    showAlert(message) {
      this.alert.message = message;
      this.alert.show = true;
    },

    async onQuerySearch() {
      const paramObject = { ...(this.searchName && {
          name: this.searchName
        }),
        ...(this.searchAddress1 && {
          address: this.searchAddress1
        }),
        // ...(this.searchAddress2 && { address2: this.searchAddress2 }),
        ...(this.searchCity && {
          city: this.searchCity
        }),
        ...(this.searchState && {
          state: this.searchState
        }),
        ...(this.searchZip && {
          zipcode: this.searchZip
        })
      };
      let paramList = [];

      for (let key in paramObject) {
        paramList.push(`${key}=${paramObject[key]}`);
      }

      const {
        data
      } = await PharmacyService.searchPharmacy(paramList.join('&'));
      this.pharmacyList = data;
      this.rows = data.length;
    },

    async onPharmacySelect(pharmacy) {
      this.selectedPharmacy = pharmacy;
    },

    async submitChanges() {
      this.alert.show = false;

      try {
        var _this$selectedPharmac;

        if (!((_this$selectedPharmac = this.selectedPharmacy) !== null && _this$selectedPharmac !== void 0 && _this$selectedPharmac['PharmacyId']) && this.checked) {
          this.showAlert('Kindly select a pharmacy');
          return;
        }

        this.buttonDisabled = true;

        if (this.checked) {
          await PharmacyService.changePharmacy(this.rowData.id, {
            id: this.selectedPharmacy['PharmacyId'],
            address: this.selectedPharmacy['Address1'],
            // address2: this.selectedPharmacy['Address2'],
            city: this.selectedPharmacy['City'],
            name: this.selectedPharmacy['StoreName'],
            state: this.selectedPharmacy['State'],
            zipcode: this.selectedPharmacy['ZipCode'],
            activetransfer: this.checked
          });
        } else {
          await PharmacyService.changePharmacy(this.rowData.id, {
            activetransfer: this.checked
          });
        }

        this.$emit('refresh-self');
        this.showDialog = false;
      } catch (err) {
        this.showAlert(err);
      } finally {
        this.buttonDisabled = false;
      }
    }

  }
};