import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from './util';

export class PharmacyService extends BaseService {
  static async getPharmacy(pharmacyid) {
    try {
      const response = await this.request({ auth: true }).get(
        `/admin/pharmacy/${pharmacyid}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async searchPharmacy(params) {
    try {
      const response = await this.request({ auth: true }).get(
        `/dosespot/pharmacy?${params}`
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }

  static async changePharmacy(patientid, payload) {
    try {
      const response = await this.request({ auth: true }).post(
        `/dosespot/changepharmacy/${patientid}`,
        payload
      );
      return new ResponseWrapper(response, response.data.body);
    } catch (error) {
      throw new ErrorWrapper(error);
    }
  }
}
